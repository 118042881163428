import React, { useState } from 'react';
import { Link } from 'gatsby';
import MailingListModal from '../MailingListModal/MailingListModal';
import * as style from './InfoContent.module.scss';

export default function InfoContent({ viewHeight }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className={style.container}>
      <MailingListModal
        isOpen={isModalOpen}
        height={viewHeight}
        closeModal={() => setIsModalOpen(false)}
      />
      <Link className={style.homeLink} to="/">
        <svg
          className={style.graphic}
          viewBox="0 0 311 361"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M160.51 155.69L285.76 195.21C285.76 195.21 304.66 203.38 284.15 215.08C263.64 226.78 189.46 274.78 189.46 274.78L158.18 232.59L244.81 204.59C244.81 204.59 146.28 213.43 118.51 179.13"
            fill="#F4D010"
          />
          <path
            d="M182.11 247.54L192.11 261.1C192.11 261.1 164 270.33 169.7 285.93C175.4 301.53 191.49 315.31 191.49 315.31C191.49 315.31 182.95 332.15 177.17 325.93C171.39 319.71 146.45 284.49 146.45 284.49L154.93 277.03L136.61 271.93L128.23 260.62L182.11 247.54Z"
            fill="#F4D010"
          />
          <path
            d="M172.74 252.23L180.14 262.23C180.14 262.23 165.72 271.23 162.65 267.46C159.58 263.69 154.7 261.81 172.74 252.23Z"
            fill="white"
          />
          <path
            d="M120.13 137.36L13.7401 205.36C13.7401 205.36 -2.38995 218.16 20.4601 224.13C43.3101 230.1 127.46 257.13 127.46 257.13L146.69 208.25L55.7001 203.74C55.7001 203.74 146.16 190.59 167.86 147.9"
            fill="#ED1C24"
          />
          <path
            d="M127.43 228.94L121.25 244.65C121.25 244.65 150.87 246.22 149.4 262.78C147.93 279.34 136 296.78 136 296.78C136 296.78 148.62 310.78 152.59 303.3C156.56 295.82 171.48 255.3 171.48 255.3L161.35 250.3L177.7 240.61L182.86 227.52L127.43 228.94Z"
            fill="#ED1C24"
          />
          <path
            d="M137.7 231L133.15 242.56C133.15 242.56 149.42 247.56 151.4 243.06C153.38 238.56 157.61 235.59 137.7 231Z"
            fill="white"
          />
          <path
            d="M285.08 28.88H21.3003V298.82H285.08V28.88Z"
            stroke="#ED1C24"
            strokeWidth="2.63"
            strokeMiterlimit="10"
          />
          <path
            d="M134.16 119.8L190 6.55004C190 6.55004 200.95 -10.89 209.4 11.16C217.85 33.21 254 113.81 254 113.81L207.55 138.33L193 48.44C193 48.44 186.77 147.18 148.66 169.44L134.16 119.8Z"
            fill="#00A651"
          />
          <path
            d="M226 117L240.93 109.12C240.93 109.12 245.77 138.38 262.06 135.12C278.35 131.86 294.39 118 294.39 118C294.39 118 309.74 129 302.71 133.76C295.68 138.52 257.09 157.84 257.09 157.84L251 148.28L243.18 165.61L230.74 172.18L226 117Z"
            fill="#00A651"
          />
          <path
            d="M229.2 126.91L240.2 121.11C240.2 121.11 246.92 136.74 242.72 139.19C238.52 141.64 235.93 146.21 229.2 126.91Z"
            fill="white"
          />
          <path
            d="M162.61 156.43C163.78 155.15 167.51 30.99 167.51 30.99C167.51 30.99 166.14 10.45 148.24 25.86C130.34 41.27 60.2402 95.1 60.2402 95.1L89.5202 138.7L145 66.45C145 66.45 100.34 146.45 123.36 184.07C123.36 184.07 152.07 171.9 162.61 156.43Z"
            fill="#2E3192"
          />
          <path
            d="M83.5001 111.15L74.0801 97.15C74.0801 97.15 56.0001 120.61 43.1701 110C30.3401 99.39 22.7701 79.63 22.7701 79.63C22.7701 79.63 4.05009 82 8.00009 89.56C11.9501 97.12 36.7601 132.38 36.7601 132.38L46.6301 126.89L45.3101 145.89L53.1501 157.57L83.5001 111.15Z"
            fill="#2E3192"
          />
          <path
            d="M75.9403 118.4L69.0003 108.08C69.0003 108.08 55.6803 118.66 58.2103 122.81C60.7403 126.96 60.8703 132.2 75.9403 118.4Z"
            fill="white"
          />
        </svg>
      </Link>
      <div className={style.text}>
        <p>
          From somewhere between yesterday and tomorrow, the cobblers at Boots
          &amp; Legs transmit a charismatic cavalcade of bespoke, artisanally
          crafted musical delights.
        </p>
        <p>
          The label draws inspiration from 80’s Italo-disco and synth-pop,
          modernising these timeless sounds for the dancefloors of today.
        </p>
        <p>
          Joyous and propulsive with a flair for the romantic, these boots were
          made for dancing.
        </p>
        <p>
          Email us: <nobr>contact(@)bootsandlegs.com</nobr>
        </p>
        <div>
          <a
            className={style.listButton}
            href="https://stats.sender.net/forms/aQYZMa/view"
            target="_blank"
            rel="noreferrer noopener"
          >
            Join our mailing list!
          </a>
        </div>
        <div>
          <a
            className={style.listButton}
            href="https://bootsandlegs.bandcamp.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Shop on Bandcamp
          </a>
        </div>
      </div>
    </div>
  );
}
