import React, { useState, useCallback, useEffect } from 'react';
import Footer from '../components/Footer/Footer';
import DesktopNav from '../components/DesktopNav/DesktopNav';
import PageContent from '../components/PageContent/PageContent';
import Hamburger from '../components/Hamburger.js/Hamburger';
import MobileMenu from '../components/MobileMenu/MobileMenu';
import InfoContent from '../components/InfoContent/InfoContent';
import PageTransition from '../components/PageTransition/PageTransition';
import Meta from '../components/Meta/Meta';

export default function Info() {
  // Viewheight
  const [viewHeight, setViewHeight] = useState(0);

  const calculateViewHeight = useCallback(() => {
    if (typeof window === 'undefined') return;
    setViewHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    calculateViewHeight();
    window.addEventListener('resize', calculateViewHeight);
    return () => window.removeEventListener('resize', calculateViewHeight);
  }, [calculateViewHeight]);

  // Hover colors
  const hoverColors = ['#17a454', '#f3cf32', '#2f3590'];
  const [hoverColorIndex, setHoverColorIndex] = useState(0);
  const changeHoverColorIndex = () => {
    setHoverColorIndex((prev) => (prev === hoverColors.length - 1 ? 0 : prev + 1));
  };

  // Mobile Menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const checkIfCloseMobileMenu = () => {
    if (typeof window === 'undefined') return;
    if (window.innerWidth > 768) setIsMenuOpen(false);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('resize', checkIfCloseMobileMenu);
    return () => window.removeEventListener('resize', checkIfCloseMobileMenu);
  });

  return (
    <PageTransition>
      <Meta title="Info | Boots &amp; Legs" />
      <MobileMenu
        isOpen={isMenuOpen}
        height={viewHeight}
        changeHoverColor={changeHoverColorIndex}
        hoverColor={hoverColors[hoverColorIndex]}
        closeMenu={() => setIsMenuOpen(false)}
      />
      <PageContent viewHeight={viewHeight}>
        <InfoContent viewHeight={viewHeight} />
        <Hamburger isOpen={isMenuOpen} handleClick={() => setIsMenuOpen((prev) => !prev)} />
        <DesktopNav hideInfo changeHoverColor={changeHoverColorIndex} hoverColor={hoverColors[hoverColorIndex]} />
        <Footer />
      </PageContent>
    </PageTransition>
  );
}
