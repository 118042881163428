import React, { useState } from 'react';
import Hamburger from '../Hamburger.js/Hamburger';
import * as style from './MailingListModal.module.scss';

export default function MailingListModal({ isOpen, height, closeModal }) {
  const [email, setEmail] = useState('');

  const submitForm = () => {
    window.open('https://tinyletter.com/Bootsnlegs', 'popupwindow', 'scrollbars=yes,width=800,height=600');
  };

  const verifyEmail = (e) => {
    e.preventDefault();
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/g;

    if (email.match(emailRegex)) {
      console.log('match');
      setEmail('');
      closeModal();
      submitForm();
    } else {
      console.log('no match');
    }
  };

  return (
    <div className={[style.container, isOpen ? style.visible : null].join(' ')} style={{ height: `${height}px` }}>
      <div className={style.relative}>
        <div className={style.closeButtonContainer}>
          <Hamburger isOpen handleClick={closeModal} bgColor="white" justCross="true" />
        </div>
        <form className={style.form} onSubmit={verifyEmail}>
          <label htmlFor="tlemail">Enter your email</label>
          <input
            className={style.emailInput}
            tabIndex="-1"
            type="text"
            placeholder="my@email.com"
            name="email"
            id="tlemail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input tabIndex="-1" type="hidden" value="1" name="embed" />
          <input className={style.joinButton} tabIndex="-1" type="submit" value="Join mailing list" />
        </form>
      </div>
    </div>
  );
}
